<template>
  <v-container fluid>
    <SessionVideo fast-auth />
  </v-container>
</template>

<script>
import SessionVideo from '@components/sessions/SessionVideo.vue'
import { mapState } from 'vuex'
export default {
  page: {
    title: 'Assistenza',
  },
  components: { SessionVideo },
  computed: {
    ...mapState('sessions', {
      session: 'current',
      pending: 'pending',
    }),
  },
}
</script>
